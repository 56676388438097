.rc-menu,
.rc-menu-submenu-selected {
  background: transparent;
  color: #FFFFFF;
  font-size: 14px;
  font-family: 'Roboto',sans-serif;
  border: none;
  border-radius: 0;
  margin: 0;
}

.rc-menu-horizontal > .rc-menu-item,
.rc-menu-horizontal > .rc-menu-submenu > .rc-menu-submenu-title {
  padding: 0 10px;
  line-height: 30px;
  min-width: 42px;
  text-align: center;
}

.rc-menu-horizontal > .rc-menu-submenu-active,
.rc-menu-horizontal > .rc-menu-item-active {
  background: #1d2126;
  color: #FFFFFF;
  border-bottom-color: #E1071B;
}

.rc-menu-item-selected.rc-menu-item-active,
.rc-menu-item-active,
.rc-menu-submenu-active > .rc-menu-submenu-title {
  background: #22282E;
  color: #FFFFFF;
}

.rc-menu-vertical > .rc-menu-item {
  padding: 12px 24px 12px 24px;
}

.rc-menu-submenu.rc-menu-submenu-vertical {
  min-width: 220px;
}

.rc-menu-item-selected {
  background: none;
}
.rc-menu-submenu {
  z-index: 200;
}

.rc-menu-item {
  display: flex;
}

.rc-menu-item,
.rc-menu-submenu {
  cursor: pointer;
  user-select: none;
}

.rc-menu-item-disabled {
  cursor: default;
}

.rc-menu-submenu > .rc-menu {
  background: #1d2126;
  color: #FFFFFF;
  box-shadow: none;
  border: 1px solid #1d2126;
}
