/* Splitter Horizontal */
.splitter-layout:not(.splitter-layout-vertical) > .layout-splitter,
.splitter-layout:not(.splitter-layout-vertical) > .layout-splitter:hover {
  background-color: #22282e !important;
  position: relative;
  width: 16px !important;
}

.light .splitter-layout:not(.splitter-layout-vertical) > .layout-splitter,
.light .splitter-layout:not(.splitter-layout-vertical) > .layout-splitter:hover {
  background-color: #eeeeee !important;
}

.splitter-layout:not(.splitter-layout-vertical) > .layout-splitter:before {
  content: '';
  pointer-events: none;
  position: absolute;
  width: 8px;
  height: 50px;
  transform: translate(0, -50%);
  top: 50%;
  left: 4px;
  background-color: #2a3038;
  transition-duration: .3s;
}

.light .splitter-layout:not(.splitter-layout-vertical) > .layout-splitter:before {
  background-color: #ffffff;
}

.splitter-layout:not(.splitter-layout-vertical).layout-changing .layout-splitter:before,
.splitter-layout:not(.splitter-layout-vertical) > .layout-splitter:hover:before {
  height: 100%;
}

.splitter-layout:not(.splitter-layout-vertical) > .layout-splitter:after,
.splitter-layout:not(.splitter-layout-vertical) > .layout-splitter:hover:after {
  content: '';
  pointer-events: none;
  position: absolute;
  transform: translate(0, -50%);
  top: 50%;
  left: 6px;
  width: 2px;
  height: 26px;
  border-left: 1px solid #373f49;
  border-right: 1px solid #373f49;
}

.light .splitter-layout:not(.splitter-layout-vertical) > .layout-splitter:after,
.light .splitter-layout:not(.splitter-layout-vertical) > .layout-splitter:hover:after {
  border-left: 1px solid #dcdcdc;
  border-right: 1px solid #dcdcdc;
}

/* Splitter Vertical */
.splitter-layout.splitter-layout-vertical > .layout-splitter,
.splitter-layout.splitter-layout-vertical > .layout-splitter:hover {
  background-color: #22282e !important;
  position: relative;
  height: 16px !important;
}

.light .splitter-layout.splitter-layout-vertical > .layout-splitter,
.light .splitter-layout.splitter-layout-vertical > .layout-splitter:hover {
  background-color: #eeeeee !important;
}

.splitter-layout.splitter-layout-vertical > .layout-splitter:before {
  content: '';
  pointer-events: none;
  position: absolute;
  height: 8px !important;
  width: 50px;
  transform: translate(-50%, 0);
  left: 50%;
  top: 4px;
  background-color: #2a3038;
  transition-duration: .3s;
}

.light .splitter-layout.splitter-layout-vertical > .layout-splitter:before {
  background-color: #ffffff;
}

.splitter-layout.splitter-layout-vertical.layout-changing .layout-splitter:before,
.splitter-layout.splitter-layout-vertical > .layout-splitter:hover:before {
  width: 100%;
}

.splitter-layout.splitter-layout-vertical > .layout-splitter:after,
.splitter-layout.splitter-layout-vertical > .layout-splitter:hover:after {
  content: '';
  pointer-events: none;
  position: absolute;
  transform: translate(-50%, 0);
  left: 50%;
  top: 4px;
  height: 8px;
  width: 66px;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><polygon points="0,0 66,0 58,8, 8,8" style="fill:#2a3038" /><rect x="20" y="2" width="26" height="1" style="fill:#373f49" /><rect x="20" y="5" width="26" height="1" style="fill:#373f49" /></svg>') no-repeat;
}

.light .splitter-layout.splitter-layout-vertical > .layout-splitter:after,
.light .splitter-layout.splitter-layout-vertical > .layout-splitter:hover:after {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><polygon points="0,0 66,0 58,8, 8,8" style="fill:#ffffff" /><rect x="20" y="2" width="26" height="1" style="fill:#dcdcdc" /><rect x="20" y="5" width="26" height="1" style="fill:#dcdcdc" /></svg>') no-repeat;
}

/* Splitter Both */
.layout-pane {
  /* min-width: 166px; */
}

.splitter-layout .layout-pane {
  overflow: hidden !important;
}

.splitter-layout .layout-splitter {
  pointer-events: none;
  visibility: hidden;
}

.splitter-layout.editable .layout-splitter {
  pointer-events: all;
  display: block;
  visibility: visible;
}

.splitter-layout .layout-splitter::before,
.splitter-layout .layout-splitter::after {
  visibility: hidden;
}

.splitter-layout.editable .layout-splitter::before,
.splitter-layout.editable .layout-splitter::after {
  visibility: visible;
}

/* resize handle */
.react-grid-item > .react-resizable-handle::after {
  border-right: 2px solid #a2a6a9;
  border-bottom: 2px solid #a2a6a9;
}

.react-grid-item > .react-resizable-handle {
  visibility: hidden;
}

.editable .react-grid-item > .react-resizable-handle {
  visibility: visible;
  bottom: 13px;
  right: 13px;
}

/* grid placeholder */
.react-grid-item.react-grid-placeholder {
  position: relative;
  background-color: transparent;
}

.react-grid-item.react-grid-placeholder:after {
  content: '';
  background-color: #2a80b9;
  border-radius: 16px;
  position: absolute;
  top: 0;
  right: 16px;
  bottom: 16px;
  left: 0;
}

/* scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #dddddd;
  border: 0px none #d6d6d6;
  border-radius: 52px;
}
::-webkit-scrollbar-thumb:hover {
  background: #a2a6a9;
}
::-webkit-scrollbar-thumb:active {
  background: #a2a6a9;
}
::-webkit-scrollbar-track {
  background: #333a44;
  border: 0px none #d6d6d6;
  border-radius: 50px;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

.light ::-webkit-scrollbar-thumb {
  background: #dddddd;
  border: 0px none #f2f2f2;
  border-radius: 52px;
}
.light ::-webkit-scrollbar-thumb:hover {
  background: #d6d6d6;
}
.light ::-webkit-scrollbar-thumb:active {
  background: #d6d6d6;
}
.light ::-webkit-scrollbar-track {
  background: #a2a6a9;
  border: 0px none #f2f2f2f2;
  border-radius: 50px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: #a2a6a9 !important;
  box-shadow: 0 0 0 1rem #373f48 inset !important;
  background-clip: content-box !important;
}

.light input:-webkit-autofill,
.light input:-webkit-autofill:hover,
.light input:-webkit-autofill:focus,
.light input:-webkit-autofill:active {
  -webkit-text-fill-color: #6e6e6f !important;
  box-shadow: 0 0 0 1rem #e5e9f2 inset !important;
}

.ply-icon {
  vertical-align: top;
  fill: lightgray;
}

.ply-icon-none {
  vertical-align: top;
  stroke:  gray;
  stroke-width: 2;
}

#menu-comparisonCaster {
  z-index: 100 !important;
}

:root {
  --dragging: 0;
}

.dropdown-selectors {
  display: inline-block;
  min-width: 55px;
}

/* hide plotly zoom notification */
.plotly-notifier {
  display: none !important;
}
